<template>
  <div>
    <CoolLightBox
      :items="photos"
      :index="index"
      :useZoomBar="true"
      @close="index = null"
    ></CoolLightBox>

    <section id="about" class="section-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm de privacidade para janelas</h2>

            <p>
              O Insulfilm de privacidade para janelas é uma solução simples para
              fornecer privacidade, segurança e beleza.
            </p>

            <p>
              O efeito do insulfilm de privacidade é uma das características
              mais comuns que existem na maioria dos filmes de controle solar e
              decorativos no mercado de filmes isolantes, mas a porcentagem de
              obstrução visual e algumas funções adicionais são diferentes.
            </p>

            <p>
              A linha clássica de insulfilm de privacidade possui apenas três
              modelos, o sombreamento preto (Blackout), sombreamento branco (
              White out) e o jateado.
            </p>

            <p>
              Existem versões em filme e adesivos de vinil fosco ou brilhante,
              mas esta linha é caracterizada principalmente pelos efeitos de
              privacidade, não é o único com esse recurso, mas são os únicos que
              tiram a visão 100% dos dois lados do vidro.
            </p>

            <p>
              Existem vários tipos de insulfilmes para janelas com função de
              bloqueio visual, total ou parcial, nas duas faces ou numa face,
              translúcidas ou desfocadas, e ainda algumas opções de cores e
              personalização.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
              />
            </div>
            <div @click="index = 1" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
              />
            </div>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import Photo from "@/components/photo/photo.vue";

//Lazy Loading
const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    QuotationCall,
    Photo,
    CoolLightBox,
  },

  data() {
    return {
      index: null,
      photos: [
        //privacidade
        {
          description: "Insulfilm branco jateado aplicado na janela para fornecer privacidade ao local",
          idLocation: 1,
          src: "/images/fotos/condominio-branco-jateado/branco-jateado-7.JPG",
          thumb: "/images/fotos/condominio-branco-jateado/small/branco-jateado-7.JPG",
          title: "Película para Janela",
        },
        {
          description:
            "Barrar a entrada de raios solares e forncecer privacidade no Bioterismo da USP",
          idLocation: 1,
          src: "/images/fotos/bioterismo-usp/DSC00389.JPG",
          thumb: "/images/fotos/bioterismo-usp/small/DSC00389.JPG",
          title: "Película para Janela",
        },
      ],
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm de privacidade para janelas");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm de privacidade para janela em comércios, escritórios, restaurantes e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>